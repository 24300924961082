<template>
  <div>
    <template
      v-for="(invite) in collaborationInvites"
    >
     <div class="profile" :key="invite.id" v-if="isUserOwner || invite.invites.targetEmail === user.email">
      <UserInvite
        :invite="invite.invites"
        :id="invite.id"
        :user="user"
        :isUserOwner="isUserOwner"
      ></UserInvite>
    </div>
    </template>
  </div>
</template>

<script>
import UserInvite from "@/components/UserInvite.vue";
export default {
  name: "UsersInvites",
  props: ["collaborationInvites", "user", "isUserOwner"],
  data() {
    return {};
  },
  components: {
    UserInvite,
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.profile {
  padding: 1rem;
  background: #fff;
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin-bottom: 2rem;
    h4{
        color: #000;
        font-size: 22px;
    }
    h2 {
        color: #333;
    }
    h6 {
        font-weight: 400;
        color: #333;
        margin: 1rem 1rem 0;
        font-size: 14px;
        b {
        font-weight: bold;
        }
    }
    p {
        color: #333;
        padding: 0 1rem;
        font-size: 14px;
    }
}
.profile-content h6 {
  margin-bottom: 0.4rem;
}
.input-group div {
  width: 100%;
}
.profile-skills ul li {
  float: left;
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 12px;
}

.profile-skills ul {
  padding: 1rem 1rem 4rem;
  display: block;
}

.profile-content {
  clear: both;
}

.profile-skills ul li.profile-skill-core {
  border: 1px solid #4c2f71;
  color: #4c2f71;
}
.profile-skills ul li.profile-skill-jobs {
  border: 1px solid #4c9674;
  color: #4c9674;
}

.toogle-this {
  overflow: hidden;
  position: relative;
}
.toggler {
  text-align: center;
  padding: 1rem 0 0 0;
  cursor: pointer;
}
.toggler .readMore {
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
  padding: 155px 40px 0px;
}
</style>
