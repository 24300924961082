var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.init)?_c('div',[_c('div',[(_vm.isUserOwner)?_c('div',{staticClass:"invite-header"},[_c('h4',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.invite.targetEmail)+" "),_c('hr')]),_c('div',{staticClass:"invite-action"},[(
            (_vm.invite.targetEmail === _vm.user.email &&
              _vm.user.role !== 'Professional') ||
              _vm.isUserOwner
          )?_c('button',{staticClass:"btn btn-sm btn-link mr-1",attrs:{"type":"button","title":"Cancel invitation"},on:{"click":function($event){return _vm.declineInvitation(_vm.invite)}}},[_vm._v(" Cancel invite ")]):_vm._e()])]):(_vm.invite.targetEmail === _vm.user.email)?_c('div',{staticClass:"invite-header"},[_c('h4',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.invite.sender)+" "),_c('hr')]),_c('div',{staticClass:"invite-action"},[(
            _vm.invite.targetEmail === _vm.user.email && _vm.user.role !== 'Professional'
          )?_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","title":"Accept invitation"},on:{"click":function($event){return _vm.acceptInvitation(_vm.invite)}}},[_vm._v(" Accept Invitation ")]):_vm._e(),(
            _vm.invite.targetEmail === _vm.user.email && _vm.user.role !== 'Professional'
          )?_c('button',{staticClass:"btn btn-sm btn-link mr-1",attrs:{"type":"button","title":"Cancel invitation"},on:{"click":function($event){return _vm.declineInvitation(_vm.invite)}}},[_vm._v(" Cancel invite ")]):_vm._e()])]):_vm._e()]),_vm._m(0)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"invite-body"},[_c('h6',[_c('b',[_vm._v("Invitation sent")])]),_c('p',[_vm._v(" To be able to publish your thesis topic, all members of the student group must sign up ")])])])}]

export { render, staticRenderFns }